(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/game-menu/assets/javascripts/game-menu.js') >= 0) return;  svs.modules.push('/components/tipsen/game-menu/assets/javascripts/game-menu.js');
"use strict";

const {
  useSelector
} = ReactRedux;
const {
  ItemsMenu
} = svs.ui.ReactItemsMenu;
const {
  ReactPaper
} = svs.ui;
const {
  TipsenModuleGameUrl
} = svs.components.sport.tipsenShared;
const {
  TipsenLink
} = svs.components.tipsen.tipsen_link;
const {
  useMemo,
  useContext
} = React;
const {
  LinkItemRoute
} = svs.components.lbUtils.linkItemRoute;
const {
  DrawContext
} = svs.components.tipsen.drawProvider;
const {
  useDrawSearchParams
} = svs.components.tipsen.drawProvider.hooks;
const {
  selectFeatureStandard,
  selectFeatureSystem,
  selectFeaturePix,
  selectFeatureMarketplace,
  selectFeatureMarketplaceTeam,
  selectFeatureSingles,
  selectFeatureExternal,
  selectSystemTypes
} = svs.components.tipsen.engine.selectors.engineSelectors;
const {
  SportinfoPathNames
} = svs.components.sportinfo.common.constants;
const {
  WALLET_PLAYER,
  WALLET_SELFSERVICE
} = svs.components.tipsen.wallet.walletType;
const {
  selectActiveWallet
} = svs.components.tipsen.walletSelectors;
const {
  CompetitionType
} = svs.components.marketplaceCompetition.constants;
const {
  useGetActiveCompetitionDesc,
  useGetActiveCompetitionType
} = svs.components.tipsen.hooks;
const {
  useBranding
} = svs.components.tipsen.hooks;
const useMenuItems = (productId, drawNumber, eventNumber) => {
  const {
    useIsPlayerInCompetition
  } = svs.components.tipsen.competition.competitionFactoryInstance.getCompetitionFactoryInstance().getCompetitionHooksForCms();
  const supportsSystems = useSelector(selectSystemTypes).length > 1;
  const drawSearchParams = useDrawSearchParams(productId, drawNumber, eventNumber);
  const hasStandard = useSelector(selectFeatureStandard);
  const hasSystem = useSelector(selectFeatureSystem);
  const hasPix = useSelector(selectFeaturePix);
  const hasMarketplace = useSelector(selectFeatureMarketplace);
  const hasMarketplaceTeam = useSelector(selectFeatureMarketplaceTeam);
  const hasSingles = useSelector(selectFeatureSingles);
  const hasExternal = useSelector(selectFeatureExternal);
  const wallet = useSelector(selectActiveWallet);
  const isPlayerInCompetition = useIsPlayerInCompetition(productId, drawNumber);
  const shouldShowCompetition = wallet ? ((wallet === null || wallet === void 0 ? void 0 : wallet.type) === WALLET_PLAYER || (wallet === null || wallet === void 0 ? void 0 : wallet.type) === WALLET_SELFSERVICE) && isPlayerInCompetition : isPlayerInCompetition;
  const competitionDesc = useGetActiveCompetitionDesc(productId);
  const competitionType = useGetActiveCompetitionType(productId);
  const isTipsEm = Boolean(competitionType === CompetitionType.TipsEM);
  const menuItems = useMemo(() => {
    const items = [shouldShowCompetition && {
      testIdSuffix: 'competition',
      label: competitionDesc !== null && competitionDesc !== void 0 ? competitionDesc : 'Tävling',
      to: {
        pathname: isTipsEm ? TipsenModuleGameUrl.TIPS_EM : TipsenModuleGameUrl.TIPS_SM,
        search: drawSearchParams.toString()
      },
      icon: 'trophy-tips-sm',
      selectedOnPaths: ["/".concat(TipsenModuleGameUrl.TIPS_SM), "/".concat(TipsenModuleGameUrl.TIPS_SM, "/").concat(TipsenModuleGameUrl.GAME_GUIDE), "/".concat(TipsenModuleGameUrl.TIPS_SM, "/").concat(SportinfoPathNames.News), "/".concat(TipsenModuleGameUrl.TIPS_SM, "/").concat(SportinfoPathNames.Table), "/".concat(TipsenModuleGameUrl.TIPS_SM, "/").concat(SportinfoPathNames.Statistic), "/".concat(TipsenModuleGameUrl.TIPS_SM, "/").concat(SportinfoPathNames.Lineup), "/".concat(TipsenModuleGameUrl.TIPS_SM, "/").concat(TipsenModuleGameUrl.YOUR_COUPON), "/".concat(TipsenModuleGameUrl.TIPS_SM, "/").concat(SportinfoPathNames.Analyze), "/".concat(TipsenModuleGameUrl.TIPS_SM, "/").concat(SportinfoPathNames.Mutuals), "/".concat(TipsenModuleGameUrl.TIPS_SM, "/").concat(SportinfoPathNames.Events), "/".concat(TipsenModuleGameUrl.TIPS_SM, "/").concat(SportinfoPathNames.PlayMakerXstat), "/".concat(TipsenModuleGameUrl.TIPS_EM), "/".concat(TipsenModuleGameUrl.TIPS_EM, "/").concat(TipsenModuleGameUrl.GAME_GUIDE), "/".concat(TipsenModuleGameUrl.TIPS_EM, "/").concat(SportinfoPathNames.News), "/".concat(TipsenModuleGameUrl.TIPS_EM, "/").concat(SportinfoPathNames.Table), "/".concat(TipsenModuleGameUrl.TIPS_EM, "/").concat(SportinfoPathNames.Statistic), "/".concat(TipsenModuleGameUrl.TIPS_EM, "/").concat(SportinfoPathNames.Lineup), "/".concat(TipsenModuleGameUrl.TIPS_EM, "/").concat(TipsenModuleGameUrl.YOUR_COUPON), "/".concat(TipsenModuleGameUrl.TIPS_EM, "/").concat(SportinfoPathNames.Analyze), "/".concat(TipsenModuleGameUrl.TIPS_EM, "/").concat(SportinfoPathNames.Mutuals), "/".concat(TipsenModuleGameUrl.TIPS_EM, "/").concat(SportinfoPathNames.Events), "/".concat(TipsenModuleGameUrl.TIPS_EM, "/").concat(SportinfoPathNames.PlayMakerXstat)]
    }, hasStandard && {
      testIdSuffix: 'standard',
      label: 'Standard',
      to: {
        pathname: TipsenModuleGameUrl.STANDARD,
        search: drawSearchParams.toString()
      },
      icon: 'enkelrader-tur',
      selectedOnPaths: ["/".concat(TipsenModuleGameUrl.STANDARD), '/spelguide', "/".concat(SportinfoPathNames.News), "/".concat(SportinfoPathNames.Table), "/".concat(SportinfoPathNames.Statistic), "/".concat(SportinfoPathNames.Lineup), '/din-kupong', "/".concat(SportinfoPathNames.Analyze), "/".concat(SportinfoPathNames.Mutuals), "/".concat(SportinfoPathNames.Events), "/".concat(SportinfoPathNames.PlayMakerXstat)]
    }, hasSystem && supportsSystems && {
      testIdSuffix: 'systems',
      label: 'Systemspel',
      to: {
        pathname: TipsenModuleGameUrl.SYSTEM,
        search: drawSearchParams.toString()
      },
      icon: 'systemspel',
      selectedOnPaths: ["/".concat(TipsenModuleGameUrl.SYSTEM), "/".concat(TipsenModuleGameUrl.SYSTEM, "/").concat(TipsenModuleGameUrl.GAME_GUIDE), "/".concat(TipsenModuleGameUrl.SYSTEM, "/").concat(SportinfoPathNames.News), "/".concat(TipsenModuleGameUrl.SYSTEM, "/").concat(SportinfoPathNames.Table), "/".concat(TipsenModuleGameUrl.SYSTEM, "/").concat(SportinfoPathNames.Statistic), "/".concat(TipsenModuleGameUrl.SYSTEM, "/").concat(SportinfoPathNames.Lineup), "/".concat(TipsenModuleGameUrl.SYSTEM, "/").concat(TipsenModuleGameUrl.YOUR_COUPON), "/".concat(TipsenModuleGameUrl.SYSTEM, "/").concat(SportinfoPathNames.Analyze), "/".concat(TipsenModuleGameUrl.SYSTEM, "/").concat(SportinfoPathNames.Mutuals), "/".concat(TipsenModuleGameUrl.SYSTEM, "/").concat(SportinfoPathNames.Events), "/".concat(SportinfoPathNames.PlayMakerXstat)]
    }, hasPix && {
      testIdSuffix: 'pix',
      label: 'Färdiga spel',
      to: {
        pathname: TipsenModuleGameUrl.PIX,
        search: drawSearchParams.toString()
      },
      icon: 'pix',
      selectedOnPaths: ["/".concat(TipsenModuleGameUrl.PIX)]
    }, hasMarketplace && {
      testIdSuffix: 'shares',
      label: 'Andelar',
      to: {
        pathname: TipsenModuleGameUrl.MARKETPLACE,
        search: drawSearchParams.toString()
      },
      icon: 'rtp',
      selectedOnPaths: ["/".concat(TipsenModuleGameUrl.MARKETPLACE)]
    }, hasMarketplaceTeam && {
      testIdSuffix: 'marketplace-team',
      label: 'Spela i lag',
      to: {
        pathname: TipsenModuleGameUrl.MARKETPLACE_TEAM,
        search: drawSearchParams.toString()
      },
      icon: 'spela-tillsammans',
      selectedOnPaths: ["/".concat(TipsenModuleGameUrl.MARKETPLACE_TEAM)]
    }, hasSingles && {
      testIdSuffix: 'singles',
      label: 'Enkelrader',
      to: {
        pathname: TipsenModuleGameUrl.SINGLES,
        search: drawSearchParams.toString()
      },
      icon: 'enkelrader',
      selectedOnPaths: ["/".concat(TipsenModuleGameUrl.SINGLES)]
    }, hasExternal && {
      testIdSuffix: 'external',
      label: 'Externa',
      to: {
        pathname: TipsenModuleGameUrl.EXTERNAL,
        search: drawSearchParams.toString()
      },
      icon: 'external-system-games',
      selectedOnPaths: ["/".concat(TipsenModuleGameUrl.EXTERNAL), "/".concat(TipsenModuleGameUrl.EXTERNAL, "/granska/:guid")]
    }].filter(Boolean);
    return items;
  }, [shouldShowCompetition, competitionDesc, isTipsEm, drawSearchParams, hasStandard, hasSystem, supportsSystems, hasPix, hasMarketplace, hasSingles, hasExternal]);
  return menuItems;
};
const GameMenu = () => {
  const {
    productId,
    drawNumber,
    eventNumber
  } = useContext(DrawContext);
  const menuItems = useMenuItems(productId, drawNumber, eventNumber);
  const brandingList = useBranding();
  let subProductBranding;
  if (brandingList.length > 1) {
    subProductBranding = brandingList[brandingList.length - 1];
  }
  return React.createElement("div", {
    className: "game-menu-container"
  }, React.createElement(ReactPaper, {
    branding: "sport",
    className: "game-menu"
  }, React.createElement(ItemsMenu, {
    branding: "game-menu",
    centered: true,
    className: "".concat(subProductBranding)
  }, menuItems.map(_ref => {
    let {
      to,
      label,
      testIdSuffix,
      icon,
      selectedOnPaths
    } = _ref;
    return React.createElement(LinkItemRoute, {
      Component: TipsenLink,
      "data-testid": "game-menu-".concat(testIdSuffix),
      icon: icon,
      iconSize: "400",
      key: to.pathname,
      label: label,
      selectedOnPaths: selectedOnPaths,
      to: to
    });
  }))));
};
setGlobal('svs.components.tipsen.gameMenu.GameMenu', GameMenu);

 })(window);